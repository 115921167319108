import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { baseButtonStyles } from './BtwButton';
import { Image } from './Image';
import { Stack } from './Stack';
const aspectImage = tv({
    base: [
        ...baseButtonStyles,
        'ring-1',
        'ring-inset',
        'bg-gray-100',
        'ring-gray-600/10',
        'hover:bg-gray-200/80',
        'hover:ring-gray-600/20',
    ],
    variants: {
        aspect: {
            video: ['aspect-video'],
            square: ['aspect-square'],
        },
        size: {
            '16': ['w-16', 'rounded'],
            full: ['w-full', 'rounded'],
        },
    },
    defaultVariants: {
        aspect: 'square',
        size: 'full',
    },
});
export const AspectImage = forwardRef(function AspectImage(props, ref) {
    const { alt, aspect, size, src, className, srcSet, 'data-testid': testId = 'card-image' } = props, rest = __rest(props, ["alt", "aspect", "size", "src", "className", "srcSet", 'data-testid']);
    return (React.createElement(Stack, { asChild: true, align: "center", justify: "center" },
        React.createElement("button", Object.assign({ className: aspectImage({ aspect, size, className }), ref: ref, type: "button" }, rest),
            React.createElement(Image, { "data-testid": testId, alt: alt, className: "max-h-[80%] max-w-[80%] rounded-sm", src: src, srcSet: srcSet }))));
});
